import { yupResolver } from "@hookform/resolvers";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { NewNotificationService } from "../../services/NewNotificationService";
import MAlert from "../elements/MAlert";
import { useLocation } from "react-router-dom";
import { ProductService } from "../../services/ProductService";
import { encodeUrl, replaceString } from "../../utils/Utils";
import { DrugService } from "../../services/DrugService";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const NotificationSchema = yup.object().shape({
  title: yup
    .string()
    .trim("The notification title cannot include leading and trailing spaces ")
    .required(),
  body: yup.string().required(),
  currentVersion: yup.string(),
  latestVersion: yup.string(),
});

const NotificationPage = () => {
  const [product, setProduct] = useState();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const productId = queryParams.get("productId");
  const module = queryParams.get("module");
  const [type, setType] = useState("General");
  const [alert, setAlert] = useState({
    show: false,
    message: null,
    type: null,
  });
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(NotificationSchema),
    defaultValues: {
      type: "General",
    },
  });

  const getNavigationUrl = (values) => {
    if (type === "General") {
      return undefined;
    }

    if (type === "AppUpdate") {
      return `update-available/true/${values.currentVersion}/${values.latestVersion}/?storeUrl=itms-apps://apps.apple.com/PK/app/id1559907067`;
    }

    if (type === "ProductDetail" && module === "mart") {
      return `${module}/product-detail/${productId}`;
    }

    if (type === "ProductDetail" && module === "pharmacy") {
      return `${module}/drug-detail/${productId}`;
    }
  };

  const getTitleAndBody = (values) => {
    if (type === "ProductDetail") {
      return {
        title: replaceString(values.title, product),
        body: replaceString(values.body, product),
        imageUrl:
          product.images.length > 0
            ? encodeUrl(`https://api.najeebmart.com/${product.images[0].path}`)
            : undefined,
      };
    }

    return {
      title: values.title,
      body: values.body,
    };
  };

  const onSubmit = async (values) => {
    const notification = {
      // "deviceTokens": ["cv0Pk2rhtES2oRtITt0uyZ:APA91bE42VfRAjctYJ7uoeQR51_ngc2c16Q0lppfaH4cFE4OGzPgzlMQyzWYN-a4E8V_ehnQ8rlMO4X2u-11lQtfYuaAAFtv4NW_7P8lpluXHsDNAXuTK4yhFY5kYQdiNnelKyYwOug1","dlQMdssIQayWG6mdZ3g7rk:APA91bF9wBNvjE-zbSWGNsAYsaaPHj-it7YdsCxJaR6-QtwoaiYt9CSLjQCTwyoeIdOi2YebAzbhVJ7LrHqd77a0ZYVvyFdeKP8Yfvaz_msRAktRrmOF4B4yA-A1-vR9VORL3-nkoc1q"],
      payload: {
        data: {
          navigation: getNavigationUrl(values), //"pharmacy/order-detail/5774",
        },
        notification: {
          ...getTitleAndBody(values),
          // imageUrl:
          //   "https://api.najeebmart.com/uploads/2020-10-12T06:58:50.389Z293.png",
        },
      },
    };

    try {
      const response = await NewNotificationService.send(notification);
      setAlert({
        show: true,
        message: "Notification sent successfully",
        type: "success",
      });
      console.log(response);
    } catch (err) {
      console.log(err?.response, "There is error...");
      setAlert({
        show: true,
        message: "Error accord while sending notification.",
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (productId) {
      // Fetch product details by id
      setType("ProductDetail");
      loadProductDetails(productId);
    } else {
      setType("General");
      setProduct(undefined);
    }
  }, [productId, module]);

  const loadProductDetails = async (productId) => {
    try {
      const response =
        module === "mart"
          ? await ProductService.findById(productId)
          : await DrugService.findById(productId);
      setProduct(response.response);
    } catch (error) {
      console.error("Error fetching product details", error);
    }
  };

  const productKeys = Object.keys(product ?? {});
  const productKeyStrings = productKeys.map((key) => `{${key}}`).join(",");

  return (
    <div>
      <MAlert alert={alert} setAlert={setAlert} />
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ marginTop: 10 }}
      >
        {!productId && (
          <FormControl fullWidth variant="outlined">
            <InputLabel id="type">Select Notification Type</InputLabel>
            <Select
              name="type"
              value={type}
              onChange={(event) => setType(event.target.value)}
              required
              id="type"
              label="type"
            >
              <MenuItem value={"General"}>General</MenuItem>
              <MenuItem value={"AppUpdate"}>App Update</MenuItem>
            </Select>
          </FormControl>
        )}
        {type === "AppUpdate" && (
          <React.Fragment>
            <TextField
              error={errors.currentVersion ? true : false}
              helperText={errors.currentVersion?.message}
              inputRef={register}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="currentVersion"
              label="Current Version"
              name="currentVersion"
              autoComplete="currentVersion"
              autoFocus
            />
            <TextField
              error={errors.latestVersion ? true : false}
              helperText={errors.latestVersion?.message}
              inputRef={register}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="latestVersion"
              label="Latest Version"
              name="latestVersion"
              autoComplete="latestVersion"
              autoFocus
            />
          </React.Fragment>
        )}
        <TextField
          error={errors.title ? true : false}
          helperText={errors.title?.message}
          inputRef={register}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="title"
          label="Title"
          name="title"
          autoComplete="title"
          autoFocus
        />
        <TextField
          error={errors.body ? true : false}
          helperText={errors.body?.message}
          inputRef={register}
          multiline
          rows={4}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="body"
          label="Description"
          name="body"
          autoComplete="body"
        />
        {product && (
          <p>
            You can use all the keys in your title as placeholder it will
            replace with actual values <br /> {productKeyStrings}
          </p>
        )}

        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
        >
          Send
        </Button>
      </form>
    </div>
  );
};

export default NotificationPage;
